<template>
  <div class="metatextDetails">
    <div v-if="loading || !metatext">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="metatext.title" :pretitle="'Metatext details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.metatext.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.metatext.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="!$store.getters.checkRole('legam.bib.metatext.modify') && $store.getters.checkRole('legam.bib.metatext.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Title</h6>
                  <b-form-input
                    id="name"
                    v-model="metatext.title"
                    placeholder="Title"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="metatext.title"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="type"
                    v-model="metatext.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="metatext.comment"></span>
                </b-col>
              </b-row>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="text-left">
                      Texts <small>({{ metatext.texts.length }} {{ metatext.texts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" visible accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-text>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-text" title="Add text" ok-only ok-variant="secondary" size="lg">
                        <TextAutocomplete @select="addText" />
                      </b-modal>
                      <TextTable
                        :showTotal="false"
                        :texts="metatext.texts"
                        @delete="deleteText"
                        :editMode="editMode"
                        v-if="metatext.texts.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'metatext'" :objectId="id" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import TextAutocomplete from '@/components/Bibliography/TextAutocomplete.vue'
  import TextTable from '@/components/Bibliography/TextTable.vue'
  import config from '@/config.js'
  // import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'MetatextDetails',
    data() {
      return {
        id: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        modalMetatext: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      TextAutocomplete,
      TextTable,
    },
    computed: {
      ...mapState({
        metatext: state => state.metatext.metatext,
        texts: state => state.metatext.texts
      })
    },
    mounted(){
      this.$store.dispatch('loadMetatext', this.id)
    },
    watch: {
      'id'(){
        this.loading = true
        this.$store.dispatch('loadMetatext', this.id)
      },
      'metatext'(){
        this.loading = false
      }
    },
    methods: {
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadMetatext', this.id)
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Changes dismissed`
        })
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveMetatext', this.metatext).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteMetatext', this.metatext).then(() => {
              router.push('/metatexts')
            })
          }
        })
      },

      addText(text){
        this.$bvModal.hide('modal-add-text')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Text added`
        })
        this.metatext.texts.push(text)
      },
      deleteText(textId){
        this.metatext.texts = this.metatext.texts.filter(
          text => text.id != textId
        )
      },

      addNewMetatextModal() {
        this.modalMetatext = null
        this.$bvModal.show('modal-add-alt-metatext')
      },
    }
  }
</script>
